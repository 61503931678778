/**
 * Autocomplete settings and utilities
 */

import memoize from "lodash.memoize";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { SEARCH_API_URL } from "../../urls";

const ONE_MINUTE_MS = 60 * 1000;

// We will setup this to allow a call ever 10 minutes
const clearCache = debounce(
    () => suggestionProvider.cache.clear(),
    10 * ONE_MINUTE_MS
);

const suggestionProvider = memoize(async term => {
    clearCache(); // Make sure the cache doesnt get too big

    // make regex expression

    const body = {
        q: term,
        perPage: 10,
    };
    const req = await fetch(`${SEARCH_API_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });

    try {
        if (req.ok) {
            const res = await req.json();
            // let data = res

            let data = [];

            if (res && res.main && res.main.total > 0 && res.main.records) {
                data = res.main.records.reduce((result, x) => {
                    if (x.resultEntity) result.push(x.resultEntity);
                    return result;
                }, []);
            }

            // updates
            return {
                data,
            };
        }
    } catch (e) {
        console.error("Autocomplete", e);
        return null;
    }
    return null;
});

export { suggestionProvider };
