<template>
    <!--Light Box-->
    <div class="lightbox" v-if="which">
        <!--First Run-->
        <div class="card firstrun" v-if="isIntro">
            <div class="close" @click="onCloseClick">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="header">
                <span v-if="this.query"
                    ><img :src="branding.Logo" />- Start Watching
                    {{ query }} Now!</span
                >
                <img v-else class="noqparam" :src="branding.Logo" />
            </div>
            <div class="firstrunIMG" @click="startProductSearch()">
                <img :src="branding.TvFirstRunImage" class="hero" />
                <p v-if="this.query" class="qword">{{ query }}</p>
                <p v-else class="qword">Search For Your Favorite TV Shows</p>
            </div>
            <div class="btnWrapper btnCenter">
                <a class="quickTipslink" @click="pickLearnTips()"
                    >{{ branding.ProductName }} - Click Here to Learn More</a
                >
            </div>
        </div>
        <!-- End of First Run-->
        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial">
            <div class="close" @click="onCloseClick">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <img :src="branding.Logo" />
            <h1>Getting the most out of {{ branding.ProductName }}</h1>
            <img :src="branding.TypeGif" class="type" />
            <ol>
                <li>
                    Go to the address bar and type
                    <strong>"{{ branding.MetaSearchKeyword }}"</strong>
                </li>
                <li>
                    After
                    <strong>"{{ branding.MetaSearchKeyword }}"</strong> hit
                    spacebar then type your search.<br />
                    (e.g. <strong>{{ branding.SearchExample }}</strong
                    >)
                </li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()">OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
    <!--End of light box-->
</template>
<script>
import { arrow } from "@tightrope/newtab";
import { getBranding } from "../branding";

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

export default {
    name: "first-run",
    props: ["which", "onClose", "onNext", "q", "doProductSearch"],
    data() {
        return {
            branding: getBranding(),
            query: this.q === "tv shows" ? "" : this.q,
        };
    },
    mounted() {
        arrow("newtab");
    },
    methods: {
        canShow(name) {
            return this.which && this.which.endsWith(name);
        },
        pickLearnTips() {
            switch (this.which) {
                case "intro":
                    this.onNext("it");
            }
        },
        onCloseClick() {
            this.onClose && this.onClose(...arguments);
        },
        async startProductSearch() {
            if (this.query) {
                this.doProductSearch();
            } else {
                this.onCloseClick();
            }
        },
    },
    computed: {
        isTutorial() {
            return this.canShow("tutorial");
        },
        isIntro() {
            return this.canShow("intro");
        },
    },
};
</script>
