const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "tv",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "TV Search",
    // same as above for new homepage
    SearchLabelHome: "TV & MOVIES SEARCH",
    // search button action label - eg, "Search Forms"
    SearchButtonText: "",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "tv",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "Family Guy, This is Us. etc..",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Search",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1:
        "Search and Watch full episodes of popular TV shows by typing in a query in the search box above.",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2:
        "You can also get exclusive videos, interviews, episode recaps and much more.",
    TvFirstRunImage: "/assets/images/LED-TV-PNG-optimized.jpg",
};

const windowBranding = (typeof branding !== "undefined" && branding) || {};

const PRODUCT_SEARCH_PROVIDERS = {
    cwseed: {
        name: "CWSEED",
        id: "cwseed",
        isDefault: false,
        url: "https://www.cwseed.com/search/?q={{QUERY}}",
    },
    tubitv: {
        name: "Tubi.TV",
        id: "tubitv",
        isDefault: true,
        url: "https://tubitv.com/search/{{QUERY}}",
    },
    tvsearch: {
        name: "TV Search",
        id: "tvsearch",
        isDefault: false,
    },
};

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(
        ([key, value]) => !value.isDefault
    );
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        };
    });
};

const DEFAULT_PRODUCT_SEARCH_PROVIDER_OLD = PRODUCT_SEARCH_PROVIDERS.tubitv.id;
const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS.tvsearch.id;

function getBranding() {
    return {
        ...defaultBranding,
        ...windowBranding,
    };
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
    DEFAULT_PRODUCT_SEARCH_PROVIDER_OLD,
};
