<template>
    <div>
        <div
            class="sBackground"
            v-bind:style="{
                'background-image': 'url(' + branding.Background + ')',
            }"
        ></div>

        <!-- Bookmark Popup  -->
        <first-run
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
            :q="query"
            :doProductSearch="toProductSearch"
        />
        <!-- First Run Popup -->
        <!----search module----->
        <div class="searchModule">
            <div class="sLogo"><img :src="branding.Logo" /></div>
            <div class="search web">
                <input
                    ref="queryInput"
                    v-model="query"
                    @return="toProductSearch()"
                    @selected="toProductSearch()"
                    @keydown.enter="toProductSearch()"
                    :placeholder="branding.AutocompletePlaceholder"
                />
                <div class="s-icon"><i class="fa fa-search"></i></div>
                <div class="error" v-if="queryError">
                    This field is required, please enter
                </div>
            </div>
            <div class="sBtnContainer">
                <button
                    class="btn-search productSearch"
                    @click="toProductSearch()"
                >
                    {{ branding.SearchLabel }}
                </button>
                <button
                    class="btn-search webSearch"
                    id="websrch-btn"
                    @click="startWebSearch()"
                >
                    Web Search
                </button>
            </div>
        </div>
        <!----end oksearch module----->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.Logo" /><span
                        ><i class="fa fa-copyright" aria-hidden="true"></i>
                        <span id="brandDetails"> {{ branding.Trademark }}</span>
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
</template>

<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getGuid, getSetting, setSetting, setExtSetting } from "../storage";
import { WEB_SEARCH_URL, MAP_SEARCH_API, openUrl } from "../urls";
import FirstRun from "../components/FirstRun.vue";
import AutoCompleteInput from "../components/AutoCompleteInput.vue";
import {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    DEFAULT_PRODUCT_SEARCH_PROVIDER_OLD,
} from "../branding";
import { documentReady } from "../common";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }

    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            query: qs.q || "tv shows",
            queryError: false,
            guid: qs.guid,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            productEngine: qs.engine || DEFAULT_PRODUCT_SEARCH_PROVIDER_OLD,
            recentSearches: JSON.parse(getSetting("recentSearches")) || [],
        };
    },
    mounted() {
        documentReady.then(async () => {
            try {
                this.guid = await getGuid();
                if (!PRODUCT_SEARCH_PROVIDERS[this.productEngine]) {
                    this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER_OLD;
                    await setExtSetting("productEngine", this.productEngine);
                }
            } catch (e) {
                // Extension not available.
            }

            this.$refs.queryInput.focus();
        });
    },
    methods: {
        startWebSearch() {
            const searchParams = {
                k: this.query,
                guid: this.guid,
            };
            openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateMapSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.queryInput.focus();
            }
            return errs === false;
        },
        async toProductSearch() {
            if (!this.validateMapSearch(true)) return;
            await this.doSearch(this.query);
        },
        async doSearch(term, addToHistory = true) {
            if (this.productEngine) {
                await openUrl(
                    PRODUCT_SEARCH_PROVIDERS[this.productEngine].url.replace(
                        "{{QUERY}}",
                        term
                    ),
                    false
                );
            }

            if (addToHistory) {
                if (this.recentSearches.unshift(term) > 5) {
                    this.recentSearches.pop();
                }
                setSetting(
                    "recentSearches",
                    JSON.stringify(this.recentSearches)
                );
            }
        },
        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
    },
};
</script>

<style>
@import "./css/serp.css";
</style>
